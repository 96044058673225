import axios from 'axios';

axios.defaults.baseURL = 'https://xyreport.xytechsz.com/manage1';

// axios.defaults.baseURL = 'https://whost.xytechsz.com/java';
// axios.defaults.baseURL = 'http://127.0.0.1:5011';
	// "http://127.0.0.1:5011/",
// 响应拦截器
axios.interceptors.response.use(
    res => res.data,  // 拦截到响应对象，将响应对象的 data 属性返回给调用的地方
    err => Promise.reject(err)
)