import React from "react";
import LoginForm from "./login_page";
import { Layout, Spin, Modal, notification, Tooltip, Button, Icon,Radio ,ConfigProvider, Popover,Avatar, Divider,Menu, Select ,} from "antd";
const { confirm } = Modal;
const { Content, Sider } = Layout;
import { login, monitorupdate } from "./api/categories";
import MeetingPage from "./meeting_page";
import MicrophoneIcon from "mdi-react/MicrophoneIcon";
import MicrophoneOffIcon from "mdi-react/MicrophoneOffIcon";
import HeartOutlineIcon from "mdi-react/HeartOutlineIcon";
import VideoIcon from "mdi-react/VideoIcon";
import VideoOffIcon from "mdi-react/VideoOffIcon";
import TelevisionIcon from "mdi-react/TelevisionIcon";
import TelevisionOffIcon from "mdi-react/TelevisionOffIcon";
import BrushOffIcon from "mdi-react/BrushIcon";
import BrushIcon from "mdi-react/BrushIcon";
import BroomIcon from "mdi-react/BroomIcon";
import HangupIcon from "mdi-react/PhoneHangupIcon";
import FullscreenIcon from "mdi-react/FullscreenIcon";
import FullscreenExitIcon from "mdi-react/FullscreenExitIcon";
import CheckboxIntermediateIcon from "mdi-react/CheckboxIntermediateIcon";
import CheckboxBlankOutlineIcon from "mdi-react/CheckboxBlankOutlineIcon";
import ShareMeeting from "./share_meeting";
import PenMeeting from "./pen_meeting";

import MediaSettings from "./setting";
import Report from "./report";

import { v4 as uuidv4 } from "uuid";
import { Client } from "ion-sdk-js";
//import { IonSFUGRPCWebSignal } from 'ion-sdk-js/lib/signal/grpc-web-impl';
import { IonSFUJSONRPCSignal } from "ion-sdk-js/lib/signal/json-rpc-impl";
import { reactLocalStorage } from "reactjs-localstorage";
import UserList from "./user";
import ChatFeed from "./chat";
import Message from "./chat/message";
import "../styles/css/app.scss";
import { ZhihuCircleFilled } from "@ant-design/icons/lib/icons";
import { AntDesignOutlined, UserOutlined,DeploymentUnitOutlined,FundProjectionScreenOutlined,PartitionOutlined,FolderAddOutlined,SisternodeOutlined,VideoCameraAddOutlined } from '@ant-design/icons';
import axios from 'axios';

const { SubMenu } = Menu;
const MyContext = React.createContext();
class Samples extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
	  selectedFile: null,
	  uploadProgress: 0,
	  totalChunks: 0,          // Total number of chunks
	  uploadedChunks: 0,       // Number of chunks successfully uploaded
	  uploadStatus: '',
	  rootSubmenuKeys: ['sub1', 'sub2', 'sub4'],
	  hostState:false,
	  hostStatelocal:false,
	  openKeys: [],
	  streams: [],
      login: true,
      loading: false,
      collapsed: false,
      loginInfo: {},
      uid: "",
      roomId: "",
      name: "",
      users: [],
      messages: [],
      localAudioEnabled: true,
      localVideoEnabled: true,
      screenSharingEnabled: false,
      vidFit: false,
      brush: false,
      isFullScreen: false,
	   selectedOption: false,
	   
	   
	   svsList:[]
    };

    this.settings = {
      selectedAudioDevice: "",
      selectedVideoDevice: "",
	  selectedVideoDeviceLocal: "",
      resolution: "hd",
      bandwidth: 1024,
      codec: "H264",
    };

    let settings = reactLocalStorage.getObject("settings");
    if (settings.codec !== undefined) {
      this.settings = settings;
    }
	
  }
  UNSAFE_componentWillMount() {
	  let roomId1=this.GetRequest2('room')
	  if(roomId1!=""){
		  this.handleLogin({
		    roomId: roomId1,
		    displayName: sessionStorage.getItem("userId")
		      ? sessionStorage.getItem("userId")
		      : "admin",
		  });
	  }else{
		  if(sessionStorage.getItem('roomId')!=""&&sessionStorage.getItem('roomId')!=null){
		  	this.handleLogin({
		  	  roomId: sessionStorage.getItem('roomId'),
		  	  displayName: sessionStorage.getItem("userId")
		  	    ? sessionStorage.getItem("userId")
		  	    : "admin",
		  	});
		  }else {
		  	this.setState({ login: false });
		  	window.location.href = "https://b1a1l.xytechsz.com/";
		  	history.go(0)
		  }
		  
	  }
    
  }
   GetRequest2(key){
      var url = window.location.hash;  
      var para = url; /* 获取属性（“?”后面的分段） */
      para=para.toString().substring(15);
     
      return para;
   }
  handleLogin = async (values) => {
    let uid = uuidv4();
    this.setState(
      {
        login: true,
        loading: false,
        uid: uid,
        roomId: values.roomId,
        name: values.displayName,
      },
      () => {
        // var url = "wss://xytechsz.virgilboss.top/meeting_ws";
        var url = "wss://auser.xytechsz.com/meeting_ws";
		// var url = "wss://127.0.0.1:8001/ws";
		
		
        this.socket = new WebSocket(url);
        this.socket.onopen = () => {
          console.log("WebSocket连接成功....");
          let message = {
            type: "joinRoom",
            data: {
              name: this.state.name,
              id: uid,
              roomId: values.roomId,
            },
          };
          this.send(message);
        };

        this.socket.onmessage = (e) => {
          var message = JSON.parse(e.data);
          // console.log(
          //   "收到的消息:{\n type= " +
          //     message.type +
          //     ", \n data =  " +
          //     JSON.stringify(message.data) +
          //     "\n"
          // );
			
          switch (message.type) {
            case "userJoin":
              break;
            case "leaveRoom":
              break;
            case "publicChat":
              if (message.data.text == "drawLine2") {
                if(message.data.name== sessionStorage.getItem("userName")&&message.data.token!= sessionStorage.getItem("token")){
              					this.removeCookie("logostatelocal");
              					 sessionStorage.clear();
                     window.location.href = "https://b1a1l.xytechsz.com/"; 
                }
                
              } else{
              				  if (JSON.parse(message.data.text).type == "drawLine") {
              				    if(message.data.name!=sessionStorage.getItem("userName")){
              				        this.meeting.bigVideo.drawCanvas(
              				            message.data.text,
              				            message.data.colorType,
              				            message.data.penZize,
              				           
              				          );
              				    }
              				    
              				  } else if (JSON.parse(message.data.text).type == "clearCanvas") {
              				    this.meeting.bigVideo.clearCanvas2();
              				  }else if(JSON.parse(message.data.text).type == "show"){
								   sessionStorage.setItem(JSON.parse(JSON.parse(message.data.text).data).streamid,JSON.parse(JSON.parse(message.data.text).data).userName);
								   
								}
								else if(JSON.parse(message.data.text).type == "mainshow"){
										
										this.meeting.onChangeVideoPositionSam(JSON.parse(JSON.parse(message.data.text).data).mainId)
										if(JSON.parse(JSON.parse(message.data.text).data).userName!=sessionStorage.getItem("userName")){
											// this.state.selectedOption=false
											this.setState({
											  selectedOption: false,
											});
										}
											// console.log(JSON.parse(JSON.parse(message.data.text).data).mainId)					   
								 // {"colorType":null,"id":"4477f75f-61ca-4643-ac81-da6d2c3c9667","name":"a2","penZize":null,"roomId":"80014","text":"{\"type\":\"mainshow\",\"data\":{\"userName\":\"a2\",\"mainId\":\"ee255ad6-2256-4a4c-bd32-19411eb89e87\"}}"}
								
								
								}
              }
              //判断是否为清除 ，如果是清除
              // this.meeting.bigVideo.clearCanvas()
              // 如果不是清除就画点
              // this.meeting.bigVideo.drawCanvas()
              break;
            case "heartPackage":
              console.log('服务端发心跳包!');
              break;
            case "updateUserList":
              let users = message.data;
              this.setState({
                users: users,
              });
              break;
          }
        };

        this.socket.onerror = (e) => {
          console.log("onerror::" + e.data);
        };

        this.socket.onclose = (e) => {
          console.log("onclose::" + e.data);
        };

        const signal = new IonSFUJSONRPCSignal(
          // "wss://xytechsz.virgilboss.top/meeting_sfu"
          // "wss://whost.xytechsz.com/meeting_sfu"
		  "wss://auser.xytechsz.com/meeting_sfu"
        );
        const client = new Client(signal);
        this.client = client;
        signal.onopen = () => {
          client.join(this.state.roomId, this.state.userName);
          this.onJoin(values, uid);
        };
      }
    );
  };

  onJoin = async (values, uid) => {
    reactLocalStorage.remove("loginInfo");
    reactLocalStorage.setObject("loginInfo", values);
    this.setState(
      {
        login: true,
        loading: false,
        uid: uid,
        loginInfo: values,
      },
      () => {
        this.meeting.publishLocalStream ();
        // this.showMessage("已连接!", "欢迎加入会议 =>" + values.roomId);
		this.onSendUserMessage("drawLine2");
		
      }
    );
  };
  showMain(){
	  if(sessionStorage.getItem("streamsobj1")){
		  this.onSendMessage(JSON.stringify({
	  type:"show",
	  data:sessionStorage.getItem("streamsobj1")
	  }))}
	  if(sessionStorage.getItem("streamsobj2")){
	  		  this.onSendMessage(JSON.stringify({
	  type:"show",
	  data:sessionStorage.getItem("streamsobj2")
	  }))}
	  if(this.state.selectedOption==true&&this.meeting.state.streams[0].id){
	  	this.onSendMessage(JSON.stringify({
	  	type:"mainshow",
	  	data:JSON.stringify({
			userName:sessionStorage.getItem("userName"),
			mainId:this.meeting.state.streams[0].id
		})
		//显示userName  发送中间id  主持人
	  	}))
	  }
	  
	  
	  
  }
  
 
  
  componentDidMount(){
          this.timerID = setInterval(
              () => {
				 this.updateSvsList()
				  this.showMain()
				 
			  },
              1000
          );
		  
		  // this.timerID2 = setInterval(
		  //     () => {
		  // 				 const api = axios.create({
		  // 				   baseURL: 'http://127.0.0.1:5011'
		  // 				 });
						
		  // 				 api.get('/report/getReportData',{
				// 				  params: {
				// 					pathologyNumber: 'J0001',
				// 					roomName: 'default'
				// 				  }})
		  // 				     .then(response => {
		  // 				       // 处理请求成功的逻辑
		  // 				       console.log(response.data);
		  // 				     })
		  // 				     .catch(error => {
		  // 				       // 处理请求失败的逻辑
		  // 				       console.error(error);
		  // 				     }); 
		  // 			  },
		  //     6000
		  // );
      }
  
      componentWillUnmount(){
          clearInterval(this.timerID)
      }
	onSendUserMessage = (msg) => {
	  let message = {
	    type: "publicChat",
	    data: {
	      name: this.state.name,
	      id: this.state.uid,
	      roomId: this.state.roomId,
	      text: msg,
	      token: sessionStorage.getItem("token"),
	     
	    },
	  };
	  this.send(message);
	};
  onSendMessage = (msg) => {
    let message = {
      type: "publicChat",
      data: {
		  
        name: sessionStorage.getItem("userName"),
        id: this.state.uid,
        roomId: this.state.roomId,
        text: msg,
        colorType: sessionStorage.getItem("color"),
        penZize: sessionStorage.getItem("pensize"),
      },
    };
    this.send(message);
  };

  onSystemMessage = (msg) => {
    let messages = this.state.messages;
    let uid = 2;
    messages.push(new Message({ id: uid, message: msg, senderName: "System" }));
    this.setState({ messages });
  };

  showMessage = (message, description) => {
    notification.info({
      message: message,
      description: description,
      placement: "topRight",
    });
  };

  send = (data) => {
    this.socket.send(JSON.stringify(data));
  };
  showManege() {
    window.location.href = "https://amanage.xytechsz.com/";
  }
  handleAudioTrackEnabled = (enabled) => {
    this.setState({
      localAudioEnabled: enabled,
    });
    this.meeting.muteMediaTrack("audio", enabled);
  };

  handleVideoTrackEnabled = (enabled) => {
    this.setState({
      localVideoEnabled: enabled,
    });
    this.meeting.muteMediaTrack("video", enabled);
  };

  handleScreenSharing = (enabled) => {
    this.setState({
      screenSharingEnabled: enabled,
      // localVideoEnabled:!enabled
    });
    this.meeting.handleScreenSharing(enabled);
    // this.meeting.muteMediaTrack("video", !enabled);
  };

  onScreenSharingCallBack = (enabled) => {
    this.setState({
      screenSharingEnabled: enabled,
    });
  };

  openOrCloseLeftContainer = (collapsed) => {
    this.setState({
      collapsed: collapsed,
    });
  };

  cleanUp = async () => {
	  
    await this.meeting.cleanUp();
  };
  setCookie = (name, value, iDay) => {
    var oDate = new Date();

    oDate.setDate(oDate.getDate() + iDay);

    document.cookie = name + "=" + value + ";expires=" + oDate;
  };
  getCookie = (name) => {
    var arr = document.cookie.split("; ");
    var i = 0;
    for (i = 0; i < arr.length; i++) {
      var arr2 = arr[i].split("=");

      if (arr2[0] == name) {
        return arr2[1];
      }
    }
    return ""; //如果没有就返回空值
  };
  removeCookie = (name) => {
    this.setCookie(name, 0, -1); //将过期时间设置为昨天，系统就自动删除了
  };
  userMonitor = async () => {
    this.removeCookie("logostatelocal");
    let monitorId = sessionStorage.getItem("monitorId");
    console.log(monitorId);
    if (monitorId) {
      console.log(monitorId);
      const res = await monitorupdate({
        id: monitorId,
      });
      console.log(res);
    }
  };
  
    getDifValue(nowtimestamp, beforetimestamp){
              var difValue = nowtimestamp - beforetimestamp;
			 
              var day = Math.floor(difValue / 1000 / 60 / 60 / 24);//天
              difValue = difValue % (1000 * 60 * 60 * 24);
              var hour = Math.floor(difValue / 1000 / 60 / 60);//小时
              difValue = difValue % (1000 * 60 * 60);
              var min = Math.floor(difValue / 1000 / 60);//分钟
              difValue = difValue % (1000 * 60);
              var second = Math.floor(difValue / 1000);
			  var money=difValue/(1000*60)*0.3
			   return "用时:" + day + "年" + hour + "小时" + min + "分钟" + second + "秒"+"   "+"产生了"+money.toFixed()+"元"
          }
         

  hangUp = async () => {
    const that = this;
    this.userMonitor();
    confirm({
      title: "退出？",
      content: this.getDifValue(new Date().getTime(),sessionStorage.getItem("toMeetingTime")) + "  是否退出会议?",
      okText: "确定",
      cancelText: "取消",
      async onOk() {
        await that.cleanUp();
        that.setState({ login: false });
		window.location.href = "https://xyreport.xytechsz.com/#/RoomList";
        // window.location.href = "https://localhost:8080/#/RoomList";
		history.go(0)
        // window.location.href = "https://alocal.xytechsz.com#/RoomList";
      },
      onCancel() {},
    });
  };

  onVideodFitClickHandler = () => {
    this.setState({
      vidFit: !this.state.vidFit,
    });
  };
  onBrushClickHandler = () => {
    this.setState({
      brush: !this.state.brush,
    });
    this.meeting.bigVideo.setDrawState();
  };

  onBroomClickHandler = () => {
  
    this.meeting.bigVideo.clearCanvas();
  };
  onFullScreenClickHandler = () => {
    let docElm = document.documentElement;
	
    if (this.fullscreenState()) {
		this.meeting.bigVideo.handleshow()
		this.meeting.localVideo.handleshow()
		this.meeting.handleshow()
		console.log(1)
      if (document.exitFullscreen) {
		  console.log(2)
		this.meeting.bigVideo.handleshow()
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
		   console.log(3)
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }

      this.setState({ isFullScreen: false });
    } else {
		this.meeting.bigVideo.handlehide()
		this.meeting.localVideo.handlehide()
		this.meeting.handlehide()
      if (docElm.requestFullscreen) {
		 
		  console.log(110)
        docElm.requestFullscreen();
      }
      //FireFox
      else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      }
      //Chrome等
      else if (docElm.webkitRequestFullScreen) {
		    console.log(10120)
        docElm.webkitRequestFullScreen();
      }
      //IE11
      else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
		
      this.setState({ isFullScreen: true });
    }
  };

  fullscreenState = () => {
    return (
      document.fullscreen ||
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      false
    );
  };
	handleCancelSelection = (e) => {
	
	   this.setState({
	      selectedOption: !this.state.selectedOption
	    });
		
	}
	
	handleFileChange = (event) => {
	    this.setState({
	      selectedFile: event.target.files[0],
	      uploadStatus: '',
		     uploadProgress: 0, 
	    }, () => {
	      // 在状态更新完成后执行的函数
	     this.handleFileUpload()
	    });
		
	  };
	  handleDelete(filePath) {
	      // Perform delete operation using fetch or axios
	      fetch(`https://virgil.goho.co/file/deleteSvsFile/${filePath}`, {
	          method: 'DELETE'
	      })
	      .then(response => response.json())
	      .then(data => {
	          // Handle success or failure based on response
	          // alert(data.message);'' // Example: Show an alert with success/failure message
	          // Optionally, update state or fetch updated file list after successful deletion
	      })
	      .catch(error => {
	          console.error('Error deleting file:', error);
	          // Handle error (e.g., show error message to user)
	      });
	  }
	  handleFileUpload = () => {
	    const { selectedFile } = this.state;
	    const fileChunkSize = 1 * 1024 * 1024; // 2MB per chunk
	    const totalChunks = Math.ceil(selectedFile.size / fileChunkSize);
	    this.setState({ totalChunks });
	  
	    const chunkPromises = [];
	    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
	      const startByte = chunkIndex * fileChunkSize;
	      const chunk = selectedFile.slice(startByte, startByte + fileChunkSize);
	      const formData = new FormData();
	      formData.append('file', chunk, `${selectedFile.name}-${chunkIndex}`);
	      formData.append('fileName', selectedFile.name);
	      formData.append('chunkIndex', chunkIndex); // Add chunkIndex parameter
	      formData.append('totalChunks', totalChunks); // Add totalChunks parameter
	  
	      const uploadPromise = axios.post('https://virgil.goho.co/file/uploadChunk', formData, {
	        headers: {
	          'Content-Type': 'multipart/form-data',
	        },
	        onUploadProgress: (progressEvent) => {
	          const { uploadedChunks } = this.state;
	          const currentProgress = Math.round(((uploadedChunks * 100) + (progressEvent.loaded * 100 / progressEvent.total)) / totalChunks);
	          console.log(`Uploaded chunk ${uploadedChunks + 1}/${totalChunks}: ${currentProgress}%`);
	          this.setState({ uploadProgress: currentProgress });
	        },
	      });
	  
	      uploadPromise.then(() => {
	        this.setState((prevState) => ({
	          uploadedChunks: prevState.uploadedChunks + 1,
	        }));
	      });
	  
	      chunkPromises.push(uploadPromise);
	    }
	  
	    Promise.all(chunkPromises)
	      .then(() => {
	        return axios.post('https://virgil.goho.co/file/mergeChunks', JSON.stringify({
	          fileName: selectedFile.name,
	          totalChunks: totalChunks,
	        }), {
	          headers: {
	            'Content-Type': 'application/json',
	          },
	        });
	      })
	      .then((mergeResponse) => {
	        console.log('File merged successfully:', mergeResponse.data);
	        this.setState({ uploadStatus: '文件上传成功!', uploadProgress: 0 });
	       
	        this.updateSvsList();
	      })
	      .catch((error) => {
	        console.error('Error uploading file:', error);
	        this.setState({ uploadStatus: '文件上传失败.', uploadProgress: 0 });
	      });
	  };
	handleFileUpload3 = () => {
	   const { selectedFile } = this.state;
	      const fileChunkSize = 1 * 1024 * 1024; // 2MB per chunk
	      const totalChunks = Math.ceil(selectedFile.size / fileChunkSize);
	      const chunkPromises = [];
	  
	      // Split the file into chunks
	      for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
	          const startByte = chunkIndex * fileChunkSize;
	          const chunk = selectedFile.slice(startByte, startByte + fileChunkSize);
	          const formData = new FormData();
	          formData.append('file', chunk, `${selectedFile.name}-${chunkIndex}`);
	          formData.append('fileName', selectedFile.name);
			  formData.append('chunkIndex', chunkIndex); // Add chunkIndex parameter
			  formData.append('totalChunks', totalChunks); // Add totalChunks parameter
	    // Upload each chunk
	    const uploadPromise = axios.post('https://virgil.goho.co/file/uploadChunk', formData, {
	    // const uploadPromise = axios.post('https://amanage.xytechsz.com/manage1/file/uploadChunk', formData, {
	      headers: {
	        'Content-Type': 'multipart/form-data',
	      },
		  onUploadProgress: (progressEvent) => {
		  const { totalChunks, uploadedChunks } = this.state;
		  const currentProgress = Math.round(((uploadedChunks * 100) + (progressEvent.loaded * 100 / progressEvent.total)) / totalChunks);
		  console.log(`Uploaded chunk ${uploadedChunks + 1}/${totalChunks}: ${currentProgress}%`);
		  this.setState({ uploadProgress: currentProgress });
		  },
	      
	    });
	    chunkPromises.push(uploadPromise);
	  }
	
	  // Once all chunks are uploaded, notify the server to merge them
	  Promise.all(chunkPromises)
	    .then((responses) => {
	      // Send request to merge chunks on server
	      return axios.post('https://virgil.goho.co/file/mergeChunks', JSON.stringify({
	      // return axios.post('https://amanage.xytechsz.com/manage1/file/mergeChunks', JSON.stringify({
	        fileName: selectedFile.name,
	        totalChunks: totalChunks,
	      }), {
	    headers: {
	      'Content-Type': 'application/json',
	    },
	  });
	})
	    .then((mergeResponse) => {
	      console.log('File merged successfully:', mergeResponse.data);
	      this.setState({ uploadStatus: '文件上传成功!', uploadProgress: 0 });
		   this.updateSvsList()
	      
	    })
	    .catch((error) => {
	      console.error('Error uploading file:', error);
	      this.setState({ uploadStatus: '文件上传失败.', uploadProgress: 0 });
	      // Optionally handle error UI or further operations
	    });
	};
	  handleFileUpload1 = () => {
	    const { selectedFile } = this.state;
	    if (selectedFile) {
		  console.log(selectedFile)
	      const formData = new FormData();
	      formData.append('file', selectedFile);
	     // axios.post('https://amanage.xytechsz.com/manage1/file/upload', formData,
	     axios.post('https://virgil.goho.co/file/upload', formData,
	                {
	                    headers: {
	                        'Content-Type': 'multipart/form-data'
	                    },// 配置用于支持大文件上传的选项
						maxContentLength: 2 * 1024 * 1024 * 1024,  // 允许的最大内容长度
						maxBodyLength: 2 * 1024 * 1024 * 1024      // 允许的最大请求体长度
	                })
	       .then((response) => {
	         console.log('File uploaded successfully:', response.data);
	         this.setState({ uploadStatus: '文件上传成功!' });
			 this.updateSvsList()
	       })
	       .catch((error) => {
	         console.error('Error uploading file:', error);
	         this.setState({ uploadStatus: '文件上传失败.' });
	       });
	    } else {
	      this.setState({ uploadStatus: '请选择文件' });
	    }
	  };
	handleChange = (e) => {
	 
	   
	}
	updateSvsList=(e)=>{
		// axios.get('https://amanage.xytechsz.com/manage1/file/svsList')
		// axios.get('https://virgil.goho.co/file/svsList')
		axios.get('https://virgil.goho.co/file/svsList')
		   .then((response) => {
		    
		     this.setState({
				 svsList:response.data
			 })
		   })
		   .catch((error) => {
		    
		   });
		
	}
	handleChange = (e) => {
	 
	   
	}
	getImage = (e) => {
	 
	   this.meeting.bigVideo.takeSnap()
	}
	getReport= (e) => {
	console.log("2111")
	   this.report.getReport()
	}
	onOpenChange = openKeys => {
			 console.log("sss")
	    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
	    if (this.state.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
	      this.setState({ openKeys });
	    } else {
	      this.setState({
	        openKeys: latestOpenKey ? [latestOpenKey] : [],
	      });
	    }
	  }
	
  onMediaSettingsChanged = (
    selectedAudioDevice,
    selectedVideoDevice,
    selectedVideoDeviceLocal,
    resolution,
    bandwidth,
    codec
  ) => {
    this.settings = {
      selectedAudioDevice,
      selectedVideoDevice,
	  selectedVideoDeviceLocal,
      resolution,
      bandwidth,
      codec,
    };
    reactLocalStorage.setObject("settings", this.settings);
  };	
  extractAfterLastUnderscore=(filePath)=> {
    // 使用 lastIndexOf 寻找最后一个下划线的位置
    const lastUnderscoreIndex = filePath.lastIndexOf('\\');
  
    if (lastUnderscoreIndex !== -1) {
      // 使用 substring 截取最后一个下划线后的字符串
      const extractedString = filePath.substring(lastUnderscoreIndex + 1);
      return extractedString;
    } else {
      return "未找到下划线";
    }
  }
	extractAfterLastUnderscore2=(filePath)=> {
	  // 使用 lastIndexOf 寻找最后一个下划线的位置
	  const lastUnderscoreIndex = filePath.replace(/\\|:/g, '*');
	  
	  return  'http://virgilmeng.yicp.io/iiif/'+lastUnderscoreIndex;
	}
	  render() {
	  const buttonWidth = 80;
	  const text = <span>Tip</span>;
	  const text1 = <span>组织AI</span>;
	  const text2 = <span>细胞AI </span>;
	  const text3 = <span>免疫组化A1</span>;
	  const text4 = <span>染色体</span>;
	  const text5 = <span>结核</span>;
	  const text6 = <span>远程会诊</span>;
	  const content = (
	    <div>
	      <p>Content</p>
	     
	    </div>
	  );
	  const content1 = (
	    <div>
	      <p>胸部AI</p>
	      <p>肺部AI</p>
	      <p>结直肠AI</p>
	      <p>乳腺AI</p>
	      <p>宫颈AI</p>
	  	
	     
	    </div>
	  );
	  const content2 = (
	    <div>
	      <p>TCT </p>
	      <p>DNA倍体   </p>
	     
	    </div>
	  );
	  	 
	  const content3 = (
	    <div>
	      <p>KI67</p>
	     
	    </div>
	  );
	  const content4 = (
	    <div>
	      <p></p>
	     
	    </div>
	  );
	  const content5 = (
	    <div>
	      <p></p>
	     
	    </div>
	  );
	  	 
	  	 
	  	 
	  	 
	  	 
	  	 
	  
	  const content6 = (
	  		
	    <div>
	  			
	  			
	     <Menu
	             mode="inline"
	             openKeys={this.state.openKeys}
	             onOpenChange={this.onOpenChange}
	             style={{ width: 256 }}
	           >
	             <SubMenu
	               key="sub1"
	               title={
	                 <span>
	                   <Icon type="mail" />
	                   <span>医生</span>
	                 </span>
	               }
	             >
	               <Menu.Item key="1">注册</Menu.Item>
	              
	             </SubMenu>
	             <SubMenu
	               key="sub2"
	               title={
	                 <span>
	                   <Icon type="appstore" />
	                   <span>需方</span>
	                 </span>
	               }
	             >
	               <Menu.Item key="5">注册</Menu.Item>
	             </SubMenu> 
	             <SubMenu
	               key="sub4"
	               title={
	                 <span>
	                   <Icon type="setting" />
	                   <span>会诊</span>
	                 </span>
	               }
	             >
	              <Menu.Item key="7">AI初筛</Menu.Item>
	              <Menu.Item key="8">一级会诊</Menu.Item>
	              <Menu.Item key="9">二级会诊</Menu.Item>
	              <Menu.Item key="10">三级会诊</Menu.Item>
	              <Menu.Item key="11">四级会诊</Menu.Item>
	             </SubMenu>
	           </Menu>
	    </div>
	  );
    const {
      login,
      loading,
      localAudioEnabled,
      localVideoEnabled,
      screenSharingEnabled,
      collapsed,
      vidFit,
    } = this.state;
    return (
      <Layout className="app-layout">
        {login ? (
          <Content className="app-center-layout">
            <Layout className="app-content-layout">
              <Layout className="app-right-layout">
                <Content style={{ flex: 1 }}>
                 <MyContext.Provider array={this.state.streams}>
                           <MeetingPage
                             onSendMessage={this.onSendMessage}
                             uid={this.state.uid}
                             collapsed={this.state.collapsed}
                             users={this.state.users}
                             settings={this.settings}
                             client={this.client}
                             localAudioEnabled={localAudioEnabled}
                             localVideoEnabled={localVideoEnabled}
                             screenSharingCallBack={this.onScreenSharingCallBack}
                           					onMediaSettingsChanged={this.onMediaSettingsChanged}
                             vidFit={vidFit}
                             ref={(ref) => {
                               this.meeting = ref;
                             }}
                           />
                 </MyContext.Provider>
				  
                  <div>
                  
                  <ConfigProvider
                      button={{
                        style: { width: buttonWidth, margin: 8 },
                      }}
                    >
                      <div className="demo">
                        
                        <div style={{ width: "45px", float: 'inline-start' ,marginTop: "80px",marginLeft:"10px",lineHeight:"60px"}}>
                          <Popover  placement="leftTop" title={text1} content={content1}>
                         
                  							  <Avatar style={{ backgroundColor: '#1677ff' }} icon={<AntDesignOutlined />} />
                          </Popover>
                          <Popover placement="left" title={text2} content={content2}>
                              <Avatar style={{ backgroundColor: '#1677ff' }} icon={<DeploymentUnitOutlined />} />
                          </Popover>
                          <Popover placement="leftBottom" title={text3} content={content3}>
                             <Avatar style={{ backgroundColor: '#1677ff' }} icon={<FundProjectionScreenOutlined />} />
                          </Popover>
                  						 <Popover placement="leftBottom" title={text4} content={content4}>
                  						    <Avatar style={{ backgroundColor: '#1677ff' }} icon={<PartitionOutlined />} />
                  						 </Popover>
                  						 
                  						  <Popover placement="leftBottom" title={text5} content={content5}>
                  						     <Avatar style={{ backgroundColor: '#1677ff' }} icon={<SisternodeOutlined />} />
                  						  </Popover>
                  						  
                  						  <Popover placement="leftBottom" title={text6} content={content6} style={{ marginLeft:"-6px !important"}}>
                  						     <Avatar size={45} style={{ backgroundColor: '#1677ff' ,fontSize:"30px"}} icon={<VideoCameraAddOutlined />} />
                  						  </Popover>
                        </div>
                        
                      </div>
                    </ConfigProvider>
                  
                  </div>
					<div className="sticky-footer" style={{position: "fixed",      zIndex: 1000,  left: 0,    bottom: "250px",    width:"300px",    backgroundColor: "#f5f5f5",    padding: "20px" }}>
					  
					  
						 <div style={{ display: 'flex', flexDirection: 'row' }}>
						      <p style={{ fontSize: "30px", color: "#ed2828", margin: 0 }}>远程读片会</p>
						      <div style={{marginTop:"10px",marginBottom:"10px" ,marginLeft:"5px"}}>
						             
						              <label htmlFor="file-upload" className="custom-file-upload" style={{marginTop:"10px",border :"1px solid #077fff",borderRadius:"5px",color:"#077fff",padding:"5px" ,bordeRadius: "4px",fontSize:"20px" }}>
						                上传SVS
						                <input id="file-upload" type="file" multiple accept=".svs" style={{ display: "none" }} onChange={this.handleFileChange} />
						              </label>
						      							   
						              
						           
						            
						      							  {this.state.uploadProgress > 0 && (
						      							     <progress value={this.state.uploadProgress} max="100" style={{ width: "100%", marginTop: "10px" }}>
						      							       {this.state.uploadProgress}%
						      							     </progress>
						      							   )}
						      							 </div>
						    </div>
					  
					  <hr />
					  
					   
							 
					
					 {this.state.svsList.reverse().map((filePath, index) => (
					     <div key={index}>
					         <p style={{ marginTop: "5px", fontSize: "16px" }}>
					             <a target="_blank" href={this.extractAfterLastUnderscore2(filePath)}>
					                 {this.extractAfterLastUnderscore(filePath)}
					             </a>
					             <button style={{ marginLeft: "10px" ,color:"red" ,padding:"2px"}} onClick={() => this.handleDelete(this.extractAfterLastUnderscore(filePath))}>
					                 删除
					             </button>
					         </p>
					     </div>
					 ))}
					  <p style={{marginTop:"15px" ,color:"#df1f1f"}} >仅限制10人观看SVS</p>
					</div>
                  <div className="app-bottom-tools-bg"></div>

                  <div className="app-bottom-tools">
                    <Tooltip title="打开关闭面板"></Tooltip>

                    <div className="app-bottom-tool">
						
					  <Radio  onClick={this.handleCancelSelection} value="option1" checked={this.state.selectedOption ==true} onChange={this.handleChange}><span style={{color:"#ffffff",}}>主持人</span></Radio>
                      <Tooltip title="禁音/取消">
                        <Button
                          size="large"
                          type="link"
                          ghost
                          style={{ color: localAudioEnabled ? "" : "red" }}
                          onClick={() =>
                            this.handleAudioTrackEnabled(!localAudioEnabled)
                          }
                        >
                          <Icon
                            component={
                              localAudioEnabled
                                ? MicrophoneIcon
                                : MicrophoneOffIcon
                            }
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          />

                          <p>禁音/取消</p>
                        </Button>
                      </Tooltip>

                      <Tooltip title="打开/关闭视频">
                        <Button
                          size="large"
                          type="link"
                          ghost
                          style={{ color: localVideoEnabled ? "" : "red" }}
                          onClick={() =>
                            this.handleVideoTrackEnabled(!localVideoEnabled)
                          }
                        >
                          <Icon
                            component={
                              localVideoEnabled ? VideoIcon : VideoOffIcon
                            }
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          />
                          <p>打开/关闭视频</p>
                        </Button>
                      </Tooltip>

                      <Tooltip title="共享桌面">
                        <Button
                          ghost
                          size="large"
                          type="link"
                          style={{ color: screenSharingEnabled ? "red" : "" }}
                          onClick={() =>
                            this.handleScreenSharing(!screenSharingEnabled)
                          }
                        >
                          <Icon
                            component={
                              screenSharingEnabled
                                ? TelevisionOffIcon
                                : TelevisionIcon
                            }
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          />
                          <p>共享桌面</p>
                        </Button>
                      </Tooltip>

                      <Tooltip title="挂断">
                        <Button
                          shape="circle"
                          size="large"
                          type="danger"
                          ghost
                          style={{ marginLeft: 16, marginRight: 16 }}
                          onClick={() => this.hangUp()}
                        >
                          <Icon
                            component={HangupIcon}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          />
                        </Button>
                      </Tooltip>
                      <ShareMeeting loginInfo={this.state.loginInfo} />
                      <MediaSettings
                        onMediaSettingsChanged={this.onMediaSettingsChanged}
                        settings={this.settings}
                      />
                      <PenMeeting
                        pen={this.state.pen}
                        changeColor={this.changeColor}
                        ref={(ref) => {
                          this.penType = ref;
                        }}
                      />

                      <Tooltip title="清除">
                        <Button
                          size="large"
                          type="link"
                          ghost
                          onClick={() => this.onBroomClickHandler()}
                        >
                          <Icon
                            component={BroomIcon}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          />
                          <p>清除</p>
                        </Button>
                      </Tooltip>
					  <MyContext.Provider array={this.state.streams}>
								<Report
								 getImage={this.getImage}	
								  onClick={() => this.getReport()}
														
								  onMediaSettingsChanged={this.onMediaSettingsChanged}
								  settings={this.settings}
								  ref={(ref) => {
								    this.report = ref;
								  }}
								/>	
					            
					  </MyContext.Provider>
					 
                    </div>

                    <Tooltip title="全屏/取消">
                      <Button
                        ghost
                        size="large"
                        type="link"
                        onClick={() => this.onFullScreenClickHandler()}
                      >
                        <Icon
                          component={
                            this.state.isFullScreen
                              ? FullscreenExitIcon
                              : FullscreenIcon
                          }
                          style={{ display: "flex", justifyContent: "center" }}
                        />
                        <p>全屏/取消</p>
                      </Button>
                    </Tooltip>
                  </div>
                </Content>
              </Layout>
            </Layout>
          </Content>
        ) : loading ? (
          <Spin size="large" tip="正在连接..." />
        ) : (
          <LoginForm handleLogin={this.handleLogin} />
        )}
      </Layout>
    );
  }
}

export default Samples;

// <Tooltip title='画笔'>
// 											    <Button
// 											        size="large"
// 											        type="link"
// 											        ghost
// 											        onClick={() => this.onBrushClickHandler()}
// 											    >
// 											        <Icon
// 											            component={
// 											                this.state.Brush ? BrushIcon : BrushOffIcon
// 											            }
// 											            style={{ display: "flex", justifyContent: "center" }}
// 											        />
// 													<p>画笔</p>
// 											    </Button>
// 											</Tooltip>
 // <div className="show-canvas">sss</div>